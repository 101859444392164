import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { map, combineLatest, Subscription, distinctUntilChanged } from 'rxjs';
import { Logger, OrganizationService } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { Product, ProductDomain, Offer, OfferDomain } from '../..';
import { CouponProductService } from '../../services/coupon-product.service';
import { CouponService } from '../../services/coupon.service';
import { Coupon } from '../../models/coupon.model';

const log = new Logger('OfferAddProductsComponent');
interface DialogData {
  couponId: string;
}

@Component({
  selector: 'app-coupon-add-products',
  templateUrl: './coupon-add-products.component.html',
  styleUrls: ['./coupon-add-products.component.scss']
})

export class CouponAddProductsComponent<T extends Product, TProductDomain extends ProductDomain<Product>, TOffer extends Offer,TOfferDomain extends OfferDomain<Offer>,> implements OnInit {
  viewModel$ = combineLatest([
    this.couponProductService.notCouponProduct$,
    this.couponProductService.notCouponProductisLoading$,
    this.couponProductService.totalProductRecords$,
    this.couponProductService.notCouponProductPage$,
  ]).pipe(
    map(([notCouponProduct, isLoading, totalProductRecords, page]) => {
      return { notCouponProduct, isLoading, totalProductRecords, page }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'ProductName', 'ProductCategoryName', 'ProductBrandName', 'Id', 'ClientKey', 'Actions'];
  productsList: ProductDomain<T>[] = [];
  selection = new SelectionModel<ProductDomain<T>>(true, []);
  productId: string = '0';
  couponId: string = '0';

  constructor(private couponProductService: CouponProductService<T>,
    private organizationService: OrganizationService,
    private route: ActivatedRoute,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private mediaObserver: MediaObserver,
    public couponService: CouponService<Coupon>,
    public dialogRef: MatDialogRef<CouponAddProductsComponent<T, TProductDomain, TOffer, TOfferDomain>>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.couponProductService.notOfferProductReload();
    this.couponId = this.route.snapshot.params['couponId'] || this.data.couponId;
    this.productId = this.route.snapshot.params['productId'];
    this.couponProductService.notOfferProduct$.subscribe((data) => {
      if(Array.isArray(data)){
         this.productsList = data;
      }
    })
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'ProductName', 'Actions'];
        }
        else {
          this.displayedColumns = ['select', 'ProductName', 'ProductCategoryName', 'ProductBrandName', 'Id', 'ClientKey', 'Actions'];
        }
      });

  }
  filtersForm = new FormGroup({
    search: new FormControl(),
  });

  closeDialog() {
    this.dialogRef.close();
  }


  saveData() {
    if (this.selection && this.selection.selected && this.selection.selected.length > 0) {
      let products: any = this.selection.selected.map(x => x.Detail.Id);
      this.couponProductService.associateProductToCoupon(this.couponId, products).subscribe({
        next: () => {
          this.matSnackBar.open(`Product(s) are added`, 'OK', DEFAULT_SNACKBAR_CONFIG);
          this.couponProductService.resetpage();
          this.couponProductService.reload();
          this.dialogRef.close(true);
        },
        error: (error) => {
          log.error('Error adding product to offer', error);

          if (error.error.value) {
            throw new Error(error.error.value);
          } else {
            throw new Error(error.message);
          }
        }
      });
    }
  }

  onSort(sortState: Sort): void {
    this.couponProductService.sort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.couponProductService.notCouponProductPage(pageEvent);
  }

  onSearch(event: any) {
    this.couponProductService.dialogSearch(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.couponProductService.dialogSearch('');
  }

  onRowDoubleClick(id: string) {
    this.router.navigate([`${id}/details`], { relativeTo: this.route });
  }

  onProductEdit(id: string) {
    this.dialogRef.close();
    this.router.navigate([`${this.organizationService.organization?.name.toLowerCase()}/products`, id, 'details'], { relativeTo: this.route });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.productsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.productsList.forEach(row => this.selection.select(row));
  }
}
