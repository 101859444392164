<ng-container *ngIf="viewModel$ | async as viewModel">
  <form [formGroup]="form">
    <div formArrayName="versions">
      <table *ngIf="viewModel.offers.length" mat-table [dataSource]="viewModel.offers" matSort
        (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
            <mat-checkbox *ngIf="canEdit()" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td class="no-ellipsis" mat-cell *matCellDef="let row">
            <mat-checkbox *ngIf="canEdit()" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!--  Image Column -->
        <ng-container matColumnDef="Image">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
            Preview
          </th>
          <td mat-cell *matCellDef="let record">
        <ng-container *ngIf="record.FacingAsset">
          <img mat-card-md-image [src]="utilityService.getOfferPreviewUrl(record.FacingAsset, PreviewMode.Thumbnail)" alt="Offer image" (error)="onImageError($event)">
        </ng-container>
        <img mat-card-md-image *ngIf="!record.FacingAsset" src="./assets/images/default-image.png"
          alt="Offer image">
      </td>
    </ng-container>

        <!-- OfferName Column -->
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name" [resizeColumn]="true">
            Name
          </th>
          <td mat-cell *matCellDef="let record"> {{ record?.Detail?.Name }}</td>
        </ng-container>

        <!-- Headline Column -->
        <ng-container matColumnDef="Headline">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Headline" [resizeColumn]="true">
            Headline
          </th>
          <td mat-cell *matCellDef="let record">{{ record?.Detail?.Headline }}</td>
        </ng-container>

        <!-- BodyCopy Column -->
        <ng-container matColumnDef="BodyCopy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by BodyCopy" [resizeColumn]="true">
            Body Copy
          </th>
          <td mat-cell *matCellDef="let record">{{ record?.Detail?.BodyCopy }}</td>
        </ng-container>

        <!-- EventType Column -->
        <ng-container matColumnDef="EventType">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
            Type
          </th>
          <td mat-cell *matCellDef="let record">{{ record?.Detail?.Label }}</td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="Status">
          <th class="w-120" mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by Status" [resizeColumn]="true">
            Status
          </th>
          <td [matMenuTriggerFor]="canEdit() ? menu: null" mat-cell *matCellDef="let record">
            <div class="offer-status column-status">
              <span class="{{record?.Detail?.Status.toString() === '0'
                    ? 'statusPENDING'
                    : 'status' + record?.Detail?.Status.toString()
                }}">
                {{ record?.Detail?.Status === '0' ? 'PENDING' :
                record?.Detail?.Status}}
                <mat-icon *ngIf="canEdit()" class="icon">arrow_drop_down</mat-icon>
              </span>

            </div>
            <mat-menu #menu="matMenu">
              <button type="button" class="statusAPPROVED" mat-menu-item
                (click)="updateTableStatus(2, record)" [disabled]="record?.Detail?.Status === 'APPROVED'">APPROVED</button>
              <button type="button" mat-menu-item class="statusPENDING"
                (click)="updateTableStatus(1, record)" [disabled]="record?.Detail?.Status === 'PENDING'">PENDING</button>
              <button type="button" class="statusSUPPRESSED" mat-menu-item
                (click)="updateTableStatus(3, record)" [disabled]="record?.Detail?.Status === 'SUPPRESSED'">SUPPRESSED</button>
            </mat-menu>
          </td>
        </ng-container>

        <!-- Version Column -->
        <ng-container matColumnDef="Version">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
            Variant
          </th>
          <td mat-cell *matCellDef="let record; let i = index;">
            <mat-form-field *ngIf="record.Variants.length > 1" class="versions" appearance="outline">
              <mat-select [(value)]="record.Detail.Id" placeholder="Select Variant"
                  (selectionChange)="onVariantChange($event.value, record)">
                    <mat-option *ngFor="let variant of record.Variants" [value]="variant.Id">
                      {{variant.OfferVariantName}}
                    </mat-option>
                  </mat-select>
            </mat-form-field>
            <span *ngIf="record.Variants.length == 1">{{record.Variants[0].OfferVariantName}}</span>
          </td>
        </ng-container>

        <!-- Rank Column -->
        <ng-container matColumnDef="Rank">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Rank" [resizeColumn]="true">
            Rank
          </th>
          <td mat-cell *matCellDef="let record">{{ record?.OfferGroupAssociatedRank }}</td>
        </ng-container>
      <!-- Comments Column -->
      <ng-container matColumnDef="Comments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
        sortActionDescription="Comments by Status" [resizeColumn]="true">
          Comments
        </th>
        <td  mat-cell *matCellDef="let record;let i = index">

            <div *ngIf="record.CommentsCount" class="font-18">
              <div id={{record.DomainId}} (click)="openDialog($event,record)" class="comments-dialog">
                <i class="fa fa-comment font-12" aria-hidden="true"></i>
                <span class="comments-count">{{record?.CommentsCount}}</span>
              </div>
            </div>
            <div *ngIf="record.CommentsCount==0" class="font-18">
              <span id={{record.DomainId}} (click)="openDialog($event,record)" class="comments-dialog">
                <i class="fa fa-comment-o font-12" aria-hidden="true"></i>
              </span>
            </div>
           
        </td>
      </ng-container>
         <!-- Tags Column -->
         <ng-container matColumnDef="Tags">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
            Tags
          </th>
          <td title="{{ record?.OfferTagNames }}" mat-cell *matCellDef="let record">{{ record?.OfferTagNames }}</td>
        </ng-container>
        
        <!-- VersionCodes Column -->
        <ng-container matColumnDef="VersionCodes">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
            Versions
          </th>
          <td title="{{ record?.VersionNames }}" mat-cell *matCellDef="let record">{{ record?.VersionNames }}</td>
        </ng-container>

        <!-- Versions Column -->
        <ng-container matColumnDef="Versions">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
            Variants
          </th>
          <td mat-cell *matCellDef="let record">
            <button *ngIf="record.VariantsCount && record.VariantsCount > 1 && record.EventOfferType === 'OFFER'" mat-button type="button"
              color="accent" [routerLink]="['../../../', record?.Detail?.Id, 'childversions']">
              Variants({{record.VariantsCount}})</button>
            <button *ngIf="record.VariantsCount && record.VariantsCount > 1 && record.EventOfferType === 'PROMO'" mat-button type="button"
              color="accent" [routerLink]="['../../../', record?.Detail?.Id,'promo', 'childversions']">
              Variants({{record.VariantsCount}})</button>
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef class="pl-20">Actions</th>
          <td mat-cell *matCellDef="let record">
            <button *ngIf="record.EventOfferType === 'OFFER'" class="offer-button" type="button" mat-button color="accent"
              [routerLink]="['../../../', record?.Detail?.Id, 'details']" [queryParams]="{ offer_filters:'true' }">
              <span *ngIf="canEdit()">Edit</span>
              <span *ngIf="!canEdit()">View Details</span>
            </button>
            <button *ngIf="record.EventOfferType === 'PROMO'" class="offer-button" type="button" mat-button color="accent"
              [routerLink]="['../../../', record?.Detail?.Id, 'promos', 'promodetails']" [queryParams]="{ offer_filters:'true' }">
              <span *ngIf="canEdit()">Edit</span>
              <span *ngIf="!canEdit()">View Details</span>
            </button>
            <button *ngIf="canEdit()" class="offer-button pl-10" type="button" mat-button
              color="accent" (click)="openRankDialog(record)">Edit Rank</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </form>


  <table *ngIf="!viewModel.offers.length">
    <tr>
      <td class="no-records" [attr.colspan]="displayedColumns.length">
        <h2>No Records Found</h2>
      </td>
    </tr>
  </table>

  <!-- pager -->
  <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
    [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.offers.length"
    showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
  </mat-paginator>

</ng-container>
