import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { Subscription, catchError, combineLatest, map } from 'rxjs';
import { AuthorizeControlService } from 'pr1-ui-components';
import { DEFAULT_SNACKBAR_CONFIG, Logger, OrganizationService, } from 'src/app/@shared';
import { Filter } from 'src/app/@shared/models/filter.model';
import { OfferGroupOffersSearchDialogComponent, OfferTag, OfferTagService, Version, VersionService, } from '../..';
import { OfferPromo } from 'src/app/modules/standard/v1';
import { PromoDomain } from 'src/app/modules/standard/v1';
import { OfferPromoService } from 'src/app/modules/standard/v1';
import { MatDialog } from '@angular/material/dialog';
import { Status } from '../../models/offer-promo-status';
import { StatusCount } from '../../models/status-count.model';
import * as dayjs from 'dayjs';
import { EventWorkflowService } from '../../services/event-workflow.service';
import { Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

const log = new Logger('OfferGroupOffersComponent');

@Component({
  selector: 'app-offer-group-offers',
  templateUrl: './offer-group-offers.component.html',
  styleUrls: ['./offer-group-offers.component.scss'],
})

export class OfferGroupOffersComponent<Tag extends OfferTag, TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>> implements OnInit, OnDestroy {
  public statusCount: StatusCount = {};
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild('offerPromoTable', { static: false }) offerPromoTable: any;
  @ViewChild('offerPromoCards', { static: false }) offerPromoCards: any;
  offerTags$ = this.offerTagService.getByEventId(
    this.route.snapshot.params['eventId'] || this.offerpromoService.eventId
  );

  viewModel$ = combineLatest([
    this.offerpromoService.isLoading$,
    this.offerpromoService.offerGrpviewMode$,
    this.offerpromoService.eventId$,
    this.versionService.allVersions$,
    this.offerTags$,
  ]).pipe(
    map(
      ([isLoading, viewMode, eventId, versions, offerTags]) => {
        return { isLoading, viewMode, versions, eventId, offerTags, };
      }
    )
  );
  filtersForm = new FormGroup({
    search: new FormControl(),
    versions: new FormControl(),
    offerTags: new FormControl(),
    offerPromos: new FormControl(),
    offerStatus: new FormControl(),
    noTags: new FormControl<boolean>(false),
    withComments: new FormControl<boolean>(false),
  });

  selectedTag = '';
  applicableWorkflowPermissions: any = null;
  awDetails: any = null;
  showStageApproval: boolean = false;
  activeStage: any = null;
  stageCompleted = false;
  stageToMarkActive: any = null;
  readOnlyMode = false;
  roles = { reviewer: 0, approver: 1, editor: 2, };
  subscription$ = new Subscription();
  userHasAccess: boolean = false;
  selectedVal = '';
  loggedInUsersRole: any;
  gotPermissions: boolean = false;
  permissions: any;
  inProgressWorkflowIndex: any;

  constructor(
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private versionService: VersionService<Version>,
    private offerTagService: OfferTagService<Tag>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private ewService: EventWorkflowService,
    private orgService: OrganizationService,
    private authorizationService: AuthorizeControlService,
    private matSnackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.getWorkflowPermissions();
    this.processRequiredInitialSetup();
    this.manuallyInvokePermissionsForCurrentWorkflow();
    this.offerpromoService.offerGroupId = this.route.snapshot.params['offerId'];
    this.offerpromoService.offerGrpReload();
  }

  manuallyInvokePermissionsForCurrentWorkflow() {
    if (this.ewService.getSelectedEventId()?.trim().length == 0) {
      this.ewService.setSelectedEventId(
        this.route.snapshot.params['eventId']?.trim()
      );
    }
  }

  isSelected(viewModel: any, status: string) {
    return (
      viewModel.filters &&
      viewModel.filters.length > 0 &&
      viewModel.filters.find((x: any) => x.displayText === status)
    );
  }

  processRequiredInitialSetup() {
    this.offerpromoService.toggleOfferGrpViewMode('CARDS');
    this.offerpromoService.eventId = this.route.snapshot.params['eventId'];
    this.versionService.eventId = this.route.snapshot.params['eventId'];
    this.offerpromoService.offerGrpPage({ pageIndex: 0, pageSize: 50 });
    this.clearSearch();
  }

  getWorkflowPermissions() {

    this.readOnlyMode = !this.authorizationService.checkAccess('edit||offermang');

    this.subscription$.add(
      this.ewService.permissionForCurrentWorkflow$.subscribe(
        (permissions: any) => {
          this.permissions = permissions;
          // If no permissions found, restrict access
          if (!permissions || permissions.length == 0) {
            this.showStageApproval = false;
            return;
          }

          const inProgressWorkflowIndex = permissions.findIndex(
            (p: any) => p.Status == 'in_progress'
          );
          this.inProgressWorkflowIndex = inProgressWorkflowIndex;

          // If an active workflow exists
          if (inProgressWorkflowIndex > -1) {
            this.applicableWorkflowPermissions =
              permissions[inProgressWorkflowIndex];

            this.getWorkflowDetails(
              this.applicableWorkflowPermissions['WorkflowId']
            );
            const stages = this.applicableWorkflowPermissions?.Stages;
            console.log(`stages: `, stages);
            const activeStageIndex = stages.findIndex(
              (s: any) => s.Status == 'active' || s.Status?.trim() == ''
            );

            // Check if active stage index
            if (activeStageIndex > -1) {
              this.activeStage =
                this.applicableWorkflowPermissions?.Stages[activeStageIndex];

              // Check if logged-in user has permission to access this event
              const userFoundIndex = this.activeStage?.Users?.findIndex(
                (u: any) =>
                  u.Email?.toLowerCase()?.trim() ==
                  this.orgService?.loggedInUser?.email?.toLowerCase().trim()
              );

              let user: any = null;
              let userCanOnlyView = true;

              if (userFoundIndex > -1) {
                // User has access to event in current workflow
                this.userHasAccess = true;

                user = this.activeStage?.Users[userFoundIndex];
                this.loggedInUsersRole = user.Role;
                if (user.Role == this.roles.reviewer) {
                  // User is reviewer & can only view the event
                  userCanOnlyView = true;
                } else {
                  // User is approver & can edit the event
                  userCanOnlyView = false;
                }
              } else {
                // User DOES NOT have access to event in current workflow
                // Allow only read only access
                userCanOnlyView = true;
                this.subscription$.unsubscribe();
              }

              // User is reviewer & hence has only read only access 
              this.readOnlyMode = userCanOnlyView

              // If user is permitted, continue with stage approval
              if (
                activeStageIndex <
                this.applicableWorkflowPermissions?.Stages?.length - 1
              ) {
                this.stageToMarkActive =
                  this.applicableWorkflowPermissions?.Stages[
                  activeStageIndex + 1
                  ];
              }

              this.showStageApproval = true;

              // Get permissions call doesnt have enough data, hence call get details
              this.getWorkflowDetails(
                this.applicableWorkflowPermissions['WorkflowId']
              );
            }
            // If there is NO Active STAGE - go into default mode
            else {
              this.showStageApproval = false;
            }
          }
          // If no workflow is active, just show events in default mode
          else {
            this.showStageApproval = false; // Stage sign off does not exist in default mode
          }
          this.gotPermissions = true;
        }
      )
    );
  }

  getWorkflowDetails(workflowId: string) {
    this.subscription$.add(
      this.ewService
        .getWorkflowDetailsForFeature(
          workflowId,
          this.orgService.silentAuthToken
        )
        .subscribe((details: any) => {
          this.awDetails = details;
        })
    );
  }

  openOffersSearchDialog(): void {
    const dialogRef = this.dialog.open(OfferGroupOffersSearchDialogComponent, {
      width: '100%',
      height: '100%',
      data: {
        eventId: this.route.snapshot.params['eventId'],
        offerGroupId: this.route.snapshot.params['offerId'],
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      console.info('OfferGroup Offers Search Dialog Component closed!');
    })

    dialogRef.componentInstance.onSelection.subscribe((offerIds: string[]) => {
      this.offerpromoService.associateOffers(this.route.snapshot.params['offerId'], offerIds).subscribe({
        next: () => {
          this.matSnackBar.open(`${offerIds.length > 1 ? 'Offer(s)' : 'Offer'} added`, 'OK', DEFAULT_SNACKBAR_CONFIG);
          this.offerpromoService.offerGrpReload();
        },
        error: (error) => {
          console.error('Error adding offers', error);

          if (error.error.value) {
            throw new Error(error.error.value);
          } else {
            throw new Error(error.message);
          }
        }
      });
    });
  }

  onSearch(event: any) {
    this.offerpromoService.offerGrpSearch(event.target.value);
  }

  onSort(sortState: Sort): void {
    this.offerpromoService.offerGrpSort(sortState);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.offerpromoService.offerGrpSearch('');
  }

  toggleView(mode: string) {
    this.offerpromoService.toggleOfferGrpViewMode(mode);
  }

  compareWithIdFn = (o1: any, o2: any) => {
    return o1 && o2 ? o1.Id === o2.Id : o1 === o2;
  };

  compareWithValFn = (o1: any, o2: any) => {
    return o1 && o2 ? o1.value === o2.value : o1 === o2;
  };

  disAssociateOffers() {
    let selectedRecordsDetails: PromoDomain<OfferPromo>[] = [];
    if (this.offerPromoTable) {
      selectedRecordsDetails = JSON.parse(JSON.stringify(this.offerPromoTable.getSelectedSectionRecords()));
    } else if (this.offerPromoCards) {
      selectedRecordsDetails = JSON.parse(JSON.stringify(this.offerPromoCards.getSelectedSectionRecords()));
    }

    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      let offerIds = selectedRecordsDetails.map(offer => offer.Detail.Id)
      this.offerpromoService.disAssociateOffers(this.route.snapshot.params['offerId'], offerIds)
        .pipe(
          catchError(error => {
            log.error('Error fetching variant details', error);
            return [];
          })
        )
        .subscribe(response => {
          this.matSnackBar.open(`${offerIds.length > 1 ? 'Offer(s)' : 'Offer'} removed.`, 'OK', DEFAULT_SNACKBAR_CONFIG);
          this.offerpromoService.offerGrpReload();
        });
    }
  }

  updateStatus(status: Status) {
    let selectedRecordsDetails: any;
    if (this.offerPromoTable) {
      selectedRecordsDetails = JSON.parse(
        JSON.stringify(this.offerPromoTable.getSelectedSectionRecords())
      );
    } else if (this.offerPromoCards) {
      selectedRecordsDetails = JSON.parse(
        JSON.stringify(this.offerPromoCards.getSelectedSectionRecords())
      );
    }
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      let statustoUpdate: any = [];
      for (let index = 0; index <= selectedRecordsDetails.length - 1; index++) {
        let variants = this.filterVariants(selectedRecordsDetails[index]);
        let recordstoupdate = variants.map((x: any) => {
          return {
            Id: x.Id,
            Status: status,
            Label: selectedRecordsDetails[index].EventOfferType
          };
        });
        statustoUpdate = [...statustoUpdate, ...recordstoupdate];
      }

      this.offerpromoService.updateStatus(statustoUpdate).subscribe({
        next: () => {
          this.offerpromoService.offerGrpReload();
          if (this.offerPromoTable) {
            this.offerPromoTable.clearSelection();
          } else if (this.offerPromoCards) {
            this.offerPromoCards.clearSelection();
          }
        },
      });
    }
  }

  filterVariants(record: any): any[] {
    // Check if record.Detail.Id is equal to any Variant.BaseId
    if (record.Detail.Id === record.Variants[0]?.BaseId) {
      return record.Variants; // Return all Variants
    }

    // Otherwise, filter Variants with BaseId equal to record.Detail.Id
    return record.Variants.filter((variant: any) => variant.Id === record.Detail.Id);
  }

  searchOfferTags(event: any) {
    this.offerTagService.search(event.target.value);
  }

  tagSelected(event: any) {
    this.offerpromoService.removeFilterByFieldName('offerTagId');
    this.selectedTag = event.option.value.OfferTagName;
    this.filtersForm['controls']['offerTags'].setValue(event.option.value.Id);
    const offerTagFilters: Filter[] = [
      {
        displayText: event.option.value.OfferTagName,
        fieldName: 'offerTagId',
        value: event.option.value.Id,
      },
    ];
  }

  triggerEmailForActiveUsersInNextStage() {
    const stages = this.applicableWorkflowPermissions?.Stages;
    const activeStageIndex = stages.findIndex((s: any) => s.Status == 'active');

    if (
      activeStageIndex != undefined &&
      activeStageIndex > -1 &&
      stages.length > activeStageIndex + 1
    ) {
      const stageToMarkActive =
        this.applicableWorkflowPermissions?.Stages[activeStageIndex + 1];
      const urlWithDomainAndEventId = window.location.href.substring(
        0,
        window.location.href.lastIndexOf('/')
      );

      const eventId = window.location.hash.split('/')[3];

      const emailTemplateString = this.ewService.getEmailBody(
        this.ewService.eventName,
        this.awDetails?.TemplateName,
        stageToMarkActive.StageName,
        `${urlWithDomainAndEventId}/offers`
      );

      const inappTemplateString = this.ewService.getInappBody(
        this.ewService.eventName,
        this.awDetails?.TemplateName,
        stageToMarkActive.StageName,
        `${urlWithDomainAndEventId}/offers`
      );

      this.ewService.triggerCustomKnockNotification(
        stageToMarkActive.users?.map((u: any) =>
          (u.email as string)?.toLowerCase()?.trim()
        ),
        emailTemplateString,
        '$users',
        'pr1-notification',
        inappTemplateString
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

}
