import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { map, combineLatest } from 'rxjs';
import { ConfirmDialogComponent, Logger } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { PromoDomain, OfferPromo } from '../..';
import { AuthorizationService } from 'src/app/@shared/services/authorization.service';
import { CouponProductService } from '../../services/coupon-product.service';
import { CouponAddProductsComponent } from '../coupon-add-products/coupon-add-products.component';

const log = new Logger('CouponProductsComponent');

@Component({
  selector: 'app-coupon-products',
  templateUrl: 'coupon.products.component.html',
  styleUrls: ['coupon.products.component.scss']
})
export class CouponProductsComponent {
  viewModel$ = combineLatest([
    this.couponProductService.isLoading$,
    this.couponProductService.viewMode$,
    this.couponProductService.productCouponId$,
    this.couponProductService.products$
  ]).pipe(
    map(([isLoading, viewMode, productCouponId, coupons]) => {
      return { isLoading, viewMode, productCouponId, coupons }
    }),
  );

  filtersForm = new FormGroup({
    search: new FormControl(),
  });
  @ViewChild('productsTable', { static: false }) productsTable: any;
  @ViewChild('productsCards', { static: false }) productsCards : any;
  public productsList: any;
  couponId = '0';

  constructor(private couponProductService: CouponProductService<any>,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,    
    private authorizationService: AuthorizationService,
  ) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.couponProductService.couponId = this.route.snapshot.params['couponId'];
    this.couponId = this.route.snapshot.params['couponId'];
    this.couponProductService.products$.subscribe((data) => {
      this.productsList = data;
    });
  }

  ngOnDestroy() { }

  onSearch(event: any) {
    this.couponProductService.search(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.couponProductService.search('');
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CouponAddProductsComponent, {
      width: '80%',
      height: '90%',
      data: { couponId: this.route.snapshot.params['couponId'] }
    });
    // this.offerProductService.dialogSearch('');
  }


  toggleView(mode: string) {
    this.couponProductService.toggleViewMode(mode);
  }

  deleteSelectedRecords() {
    let selectedRecords: any;
    if (this.productsTable) {
      selectedRecords = this.productsTable.getSelectedSectionRecords();
    } else if (this.productsCards ){
      selectedRecords = this.productsCards.getSelectedSectionRecords();
    }
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Product?`,
        },
        disableClose: true,
      });


      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            const products = selectedRecords.map((product: any) => product.Detail.Id)
            this.couponProductService.disassociateProductToCoupon(this.couponId, products).subscribe({
              next: () => {
                this.matSnackBar.open(`Product(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                if (this.productsTable) {
                  this.productsTable.clearSelection();
                } else if (this.productsCards) {
                  this.productsCards.clearSelection();
                }
                this.couponProductService.reload();
                this.router.navigate([`../`, 'products'], { relativeTo: this.route });
              },
              error: (error) => {
                log.error('Error adding store to version', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }

  }
  cancel(): void {
    this.router.navigate([`../../`], { relativeTo: this.route });
  }

  getOfferName(offers: any) {
    if (offers && offers.length > 0) {
      const offerDetail = offers.find((x: PromoDomain<OfferPromo>) => x.DomainId === this.route.snapshot.params['couponId']);
      if (offerDetail && offerDetail.Detail) {
        return offerDetail.Detail.Name;
      } else {
        return '';
      }
    }
    return '';
  }

  canEdit(): boolean {
    const hasAccess = this.authorizationService.checkAccess('edit||offermang');
    return hasAccess;
  }
}
