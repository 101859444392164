<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
  <form [formGroup]="form">
    <div formArrayName="versions">
      <div class="grid" *ngIf="viewModel.offers.length">
        <mat-card appearance="outlined" *ngFor="let offer of viewModel.offers; index as i">
          <mat-card-content>
            <span class="rank" *ngIf="offer?.OfferGroupAssociatedRank">Rank : {{offer.OfferGroupAssociatedRank }}</span>
            <div class="offer-status">
              <span *ngIf="canEdit()" [matMenuTriggerFor]="menu"
                class="ellipsis-3-line {{(offer?.Detail?.Status?.toString() ?? '') === '0'? 'statusPENDING': 'status' + (offer?.Detail?.Status?.toString() ?? '')}}">
                {{(offer?.Detail?.Status?.toString() ?? '') === '0'? "PENDING": offer?.Detail?.Status}}
                <mat-icon class="icon">arrow_drop_down</mat-icon>
              </span>
              <span *ngIf="!canEdit()"
                class="ellipsis-3-line {{(offer?.Detail?.Status?.toString() ?? '') === '0'? 'statusPENDING': 'status' + (offer?.Detail?.Status?.toString() ?? '')}}">
                {{(offer?.Detail?.Status?.toString() ?? '') === '0'? "PENDING": offer?.Detail?.Status}}
              </span>
            </div>
            <mat-menu #menu="matMenu" class="status-menu">
              <button type="button" mat-menu-item class="statusAPPROVED"
                [disabled]="offer?.Detail?.Status?.toString() === 'APPROVED'"
                (click)="updateTableStatus(2, offer)">APPROVED</button>
              <button type="button" mat-menu-item class="statusPENDING"
                [disabled]="offer?.Detail?.Status?.toString() === 'PENDING'"
                (click)="updateTableStatus(1, offer)">PENDING</button>
              <button type="button" mat-menu-item class="statusSUPPRESSED"
                [disabled]="offer?.Detail?.Status?.toString() === 'SUPPRESSED'"
                (click)="updateTableStatus(3, offer)">SUPPRESSED</button>
            </mat-menu>
            <div class="grid-col-span-2 offer-name" title="{{ offer?.Detail?.Name }}" fxLayout="row wrap"
              fxLayoutAlign="start start" fxLayoutGap="15px">
              <mat-checkbox *ngIf="!readOnlyMode" (click)="$event.stopPropagation()" fxFlex="30px" class="card-checkbox"
                (change)="$event ? selection.toggle(offer) : null" [checked]="selection.isSelected(offer)">
              </mat-checkbox>
            </div>
            <div fxLayoutAlign="center center" class="offer-image">
              <ng-container *ngIf="offer.FacingAsset">
                <img mat-card-md-image [src]="utilityService.getOfferPreviewUrl(offer.FacingAsset, PreviewMode.Medium)"
                  alt="Offer image" (error)="onImageError($event)">
              </ng-container>
              <img mat-card-md-image *ngIf="!offer.FacingAsset" src="./assets/images/default-image.png"
                alt="Offer image" />
            </div>
            <div class="offer-content" fxLayout="column">
              <div *ngIf="offer.Variants.length > 1" class="offer-bodycopy">
                <mat-form-field class="versions" appearance="outline">
                  <mat-select [(value)]="offer.Detail.Id" placeholder="Select Variant"
                    (selectionChange)="onVariantChange($event.value, offer)">
                    <mat-option *ngFor="let variant of offer.Variants" [value]="variant.Id">
                      {{variant.OfferVariantName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="offer-headline">
                <span [innerHtml]="offer?.PricingPreviewHtml"></span>
              </div>
            </div>

            <div class="card-footer grid-col-span-2" fxLayout="row wrap" fxLayoutGap="15px">
              <span title="{{ offer?.OfferTagNames }}" class="card-footer-text w-100">
                Tags : {{ offer?.OfferTagNames }}</span>
              <span title="{{ offer?.VersionNames }}" class="card-footer-text version-codes w-100">
                Versions : {{ offer?.VersionNames }}</span>
              <div class="card-footer-text">
                <span class="footer-text">{{ offer.EventOfferType }}</span>
                <span class="footer-text display">Client Key:
                  <span title="{{ offer?.Detail?.ClientKey }}">{{
                    offer?.Detail?.ClientKey
                    }}</span></span>
              </div>
              <div fxLayOut="row" class="card-footer-btns">
                <button *ngIf="offer.VariantsCount && offer.VariantsCount > 1 && offer.EventOfferType === 'OFFER'"
                  mat-button type="button" color="accent" [routerLink]="['../../../', offer?.Detail?.Id, 'childversions']">
                  Variants ({{offer.VariantsCount}})</button>
                <button *ngIf="offer.VariantsCount && offer.VariantsCount > 1 && offer.EventOfferType === 'PROMO'"
                  mat-button type="button" color="accent" [routerLink]="['../../../', offer?.Detail?.Id,'promo', 'childversions']">
                  Variants ({{offer.VariantsCount}})</button>
                <button *ngIf="offer.EventOfferType === 'OFFER'" class="offer-button" type="button" mat-button
                  color="accent" [routerLink]="['../../../', offer?.Detail?.Id, 'details']" [queryParams]="{ offer_filters:'true' }">
                  <span *ngIf="canEdit()">Edit</span>
                  <span *ngIf="!canEdit()">View Details</span>
                </button>
                <button *ngIf="offer.EventOfferType === 'PROMO'" class="offer-button" type="button" mat-button
                  color="accent" [routerLink]="['../../../', offer?.Detail?.Id, 'promos', 'promodetails']"
                  [queryParams]="{ offer_filters:'true' }">
                  <span *ngIf="canEdit()">Edit</span>
                  <span *ngIf="!canEdit()">View Details</span>
                </button>
                <button *ngIf="canEdit()" class="offer-button" type="button" mat-button color="accent"
                  (click)="openRankDialog(offer)">Edit Rank</button>
                <span class="comments pl-10">
                  <ng-container
                    *ngIf="offer?.CommentsCount !== undefined && offer?.CommentsCount! > 0; else noComments">
                    <div id={{offer.DomainId}} (click)="openDialog($event,offer)" class="comments-dialog">
                      <i class="fa fa-comment font-12" aria-hidden="true"></i>
                      <span class="comments-count">{{offer?.CommentsCount}}</span>
                    </div>
                  </ng-container>
                  <ng-template #noComments>
                    <span id={{offer.DomainId}} (click)="openDialog($event,offer)" class="comments-dialog">
                      <i class="fa fa-comment-o font-12" aria-hidden="true"></i>
                    </span>
                  </ng-template>
                </span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>

  <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
    [pageSizeOptions]="[5, 10, 20, 50, 100, 500]" (page)="onPage($event)" *ngIf="viewModel.offers.length"
    showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
  </mat-paginator>

  <table *ngIf="!viewModel.offers.length">
    <tr>
      <td class="no-offers">
        <h2>No offers Found</h2>
      </td>
    </tr>
  </table>
</ng-container>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>