import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OfferPromo, PromoDomain } from '../..';

interface OfferModelData {
  offer: PromoDomain<OfferPromo>,
}

@Component({
  selector: 'app-offergroup-offer-rank-dialog',
  templateUrl: './offergroup-offer-rank-dialog.component.html',
  styleUrls: ['./offergroup-offer-rank-dialog.component.scss']
})
export class OffergroupOfferRankDialogComponent {
  offer!: PromoDomain<OfferPromo>;
  modelData!: OfferModelData;
  @Output() onSelection: EventEmitter<{ offer: PromoDomain<OfferPromo>, rank: number }> = new EventEmitter();

  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<OffergroupOfferRankDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OfferModelData
  ) {
    this.modelData = data;
    this.offer = this.modelData.offer;

    this.form = new FormGroup({
      rank: new FormControl(this.offer.OfferGroupAssociatedRank, [Validators.required, Validators.min(1)])
    });
  }

  get rankControl() {
    return this.form.get('rank');
  }

  save(): void {
    if (this.form.valid) {
      const rank: number = this.rankControl?.value;
      this.onSelection.emit({ offer: this.offer, rank });

      this.closeDialog();
    } else {
      console.error('Form is invalid');
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
