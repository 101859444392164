import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnInit, } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger, PreviewMode, UtilityService } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { OfferPromo, PromoDomain, OfferPromoService, OfferGroupDomain, Offer } from '../..';
import { SelectionModel } from '@angular/cdk/collections';
import { Status } from '../../models/offer-promo-status';
import { EventWorkflowService } from '../../services/event-workflow.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommentsComponent } from 'pr1-ui-components';
import { MatTableDataSource } from '@angular/material/table';
import { DOCUMENT } from '@angular/common';
import { catchError } from 'rxjs/operators';
import { OfferCopy } from '../../models/offer-copy.model';

const log = new Logger('OfferListTableComponent');

@Component({
  selector: 'app-offer-list-table',
  templateUrl: './offer-list-table.component.html',
  styleUrls: ['./offer-list-table.component.scss'],
})
export class OfferListTableComponent<TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>> implements OnInit, AfterViewInit {
  @Input() set readOnly(val: boolean) {
    this.readOnlyMode = val;
    this.offerpromoService.setOfferDetailsReadOnly(val);
  }

  Status: typeof Status = Status;
  PreviewMode = PreviewMode;
  viewModel$ = combineLatest([
    this.offerpromoService.getAllEventOfferTypesByEventId$,
    this.offerpromoService.isLoading$,
    this.offerpromoService.totalRecords$,
    this.offerpromoService.page$,
  ]).pipe(
    map(([eventOfferTypes, isLoading, totalRecords, page]) => {
      if (eventOfferTypes.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0;
        page.pageIndex = 0;
        this.offerpromoService.page(page);
        this.offerpromoService.reload();
      }

      this.eventOfferTypes = new MatTableDataSource(eventOfferTypes);
      return { eventOfferTypes, isLoading, totalRecords, page };
    })
  );
  eventOfferTypes: MatTableDataSource<OfferGroupDomain<Offer>> = new MatTableDataSource();
  selection = new SelectionModel<OfferGroupDomain<Offer>>(true, [], true, (o1, o2) => o1.DomainId === o2.DomainId);
  readOnlyMode = true;
  isCommentDialogOpened = false;
  private subscriptions: Subscription[] = [];
  displayedColumns = ['select', 'Image', 'Name', 'Headline', 'BodyCopy', 'EventType', 'Status', 'Tags', 'VersionCodes', 'Version', 'Rank', 'Comments', 'Versions', 'Actions',];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private mediaObserver: MediaObserver,
    public utilityService: UtilityService,
    private ewService: EventWorkflowService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.detectViewportSizeChange();
    this.offerpromoService.commentDialogBoxStatus$.subscribe((status: boolean) => { this.isCommentDialogOpened = status });
  }

  ngAfterViewInit() {
    if (this.ewService.readOnlyMode != null) {
      this.readOnlyMode = this.ewService.readOnlyMode;
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  detectViewportSizeChange() {
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.subscriptions.push(this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        )
      )
      .subscribe((change) => {
        if (change.some((x) => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'Name', 'Actions'];
        } else if (change.some((x) => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'Name', 'Headline', 'Actions'];
        } else {
          this.displayedColumns = ['select', 'Image', 'Name', 'Headline', 'BodyCopy', 'EventType', 'Status', 'Tags', 'VersionCodes', 'Version', 'Rank', 'Comments', 'Versions', 'Actions',];
        }
      }));
  }

  fetchVariantDetails(variantId: string, offerType: OfferGroupDomain<Offer>): void {
    if (variantId && offerType.EventOfferType && offerType.EventOfferType !== 'OFFERGROUP') {
      this.offerpromoService.getVariantDetails(variantId, offerType.EventOfferType)
        .pipe(
          catchError(error => {
            log.error('Error fetching variant details', error);
            return [];
          })
        )
        .subscribe(response => {
          // Update offerType properties with the response, excluding Variants and VariantsCount
          Object.keys(response).forEach(key => {
            if (key !== 'Variants' && key !== 'VariantsCount') {
              offerType[key] = response[key];
            }
          });
        });
    }
  }

  onVariantChange(variantId: string, offerType: OfferGroupDomain<Offer>): void {
    this.fetchVariantDetails(variantId, offerType);
  }

  onSort(sortState: Sort): void {
    this.offerpromoService.sort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.offerpromoService.page(pageEvent);
  }

  getSelectedSectionRecords() {
    return this.selection.selected;
  }

  clearSelection() {
    this.selection.clear();
  }

  updateStatus(status: Status, row: OfferGroupDomain<Offer>) {
    let selectedRecordsDetails: OfferGroupDomain<Offer>[] = [JSON.parse(JSON.stringify(row))];
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateRecordsStatus(selectedRecordsDetails: Array<OfferGroupDomain<Offer>>, status: Status) {
    let details: Array<OfferCopy> = [];
        if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
          details = selectedRecordsDetails.map((record: OfferGroupDomain<Offer>) => {
    
            let copy: OfferCopy = {
              Id: record.Detail.Id,
              Label: record.EventOfferType,
              Status: status as unknown as string
            };
    
            return copy;
          });
      this.offerpromoService.updateStatus(details)
        .pipe(
          catchError(error => {
            log.error('Error updating status', error);
            return [];
          })
        )
        .subscribe({
          next: () => {
            this.offerpromoService.reload();
            this.clearSelection();
            this.offerpromoService.updateRecordStatus(new Date());
          },
        });
    }
  }

  canEdit(): boolean {
    return !this.readOnlyMode;
  }

  openDialog($event: any, offer: OfferGroupDomain<Offer>) {
    if (!this.isCommentDialogOpened) {
      const commentElementStyle = offer.DomainId ? this.document.getElementById(offer.DomainId) as HTMLElement : null;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        headline: offer.Detail.Headline,
        channel: offer.EventOfferType,
        parentId: offer.Detail.Id,
        clientKey: offer.Detail.ClientKey,
        bodyCopy: offer.Detail.BodyCopy,
      };
      const confirmDialog = this.dialog.open(CommentsComponent, dialogConfig);
      confirmDialog.afterOpened().subscribe(() => {
        if (commentElementStyle) {
          commentElementStyle.style.color = "blue";
          this.offerpromoService.commentDialogBoxStatusBehaviorSubject.next(true);
        }
      });
      confirmDialog.afterClosed().subscribe((data) => {
        if (data > 0) {
          Object(offer).CommentsCount = data;
        }
        if (commentElementStyle) {
          commentElementStyle.style.color = "black";
          this.offerpromoService.commentDialogBoxStatusBehaviorSubject.next(false);
        }
      });
    }
  }

  updateReadonlyMode(val: boolean) {
    this.readOnlyMode = val;
    this.offerpromoService.setOfferDetailsReadOnly(val);
  }

  onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = './assets/images/default-image.png'; // Path to your default image
  }

  isAllSelected(): boolean {
    return this.eventOfferTypes.data.every(offer => this.selection.isSelected(offer));
  }

  toggleSelect(checked: boolean, offer: OfferGroupDomain<Offer>) {
    if (checked) {
      if (!this.selection.isSelected(offer)) {
        this.selection.select(offer);
      }
    } else {
      this.selection.deselect(offer);
    }
  }

  toggleSelectAll(checked: boolean): void {
    if (checked) {
      this.eventOfferTypes.data.forEach(offer => {
        if (!this.selection.isSelected(offer)) {
          this.selection.select(offer);
        }
      });
    } else {
      this.eventOfferTypes.data.forEach(offer => {
        this.selection.deselect(offer);
      });
    }
  }

  isSomeSelectedOnPage(): boolean {
    return this.eventOfferTypes.data.some(offer => this.selection.isSelected(offer));
  }

  getStatusClass(offer: OfferGroupDomain<Offer>): string {
    const status = offer?.Detail?.Status;
    return status === '0' ? 'statusPENDING' : 'status' + status;
  }

  getStatusText(offer: OfferGroupDomain<Offer>): string {
    const status = offer?.Detail?.Status;
    return status === '0' ? 'PENDING' : status || '';
  }

  trackByFn(index: number, item: OfferGroupDomain<Offer>): number {
    return item.DomainId ? parseInt(item.DomainId, 10) : 0;
  }

}
