<!-- page title -->
<ng-container *ngIf="viewModel$ | async as viewModel">
  <ng-container *ngIf="viewModel.montageBuilderStatus==false">
    <div class="offer-main-section">
      <div class="offerMainDiv">
        <div class="headerWidth">
          <h1 class="headerMargin">Offer Management</h1>
        </div>

        <div *ngIf="offerId !== '0'" flex="row" fxLayoutAlign="space-between" class="arrows" style="margin-left: 55%">
          <button mat-stroked-button class="nextButtons" title="Previous" [disabled]="currentTypeIndex <= 0" (click)="previousOffer()" >
            <mat-icon>skip_previous</mat-icon>
          </button>

          <button mat-stroked-button class="nextButtons" title="Next" [disabled]="currentTypeIndex >= offerTypeIdsByEventList.length - 1" (click)="nextOffer()" >
            <mat-icon>skip_next</mat-icon>
          </button>
        </div>
      </div>

      <ng-container *ngIf="viewModel?.event?.EventName">
        <h2 class="event-name">{{viewModel.event.EventName}}
          <link href="#">
        </h2>
      </ng-container>

      <ng-container *ngIf="labelType != LabelType.OFFERGROUP">
        <div *ngIf="offerId !== '0' && readOnlyMode != true" fxLayout="row wrap" fxLayoutGap.gt-sm="16px"
          fxLayout.lt-md="column">
          <button [disabled]="disableNewVersion" (click)="openDialog()" class="versionBtn" mat-stroked-button
            type="button" color="accent">
            <fa-icon [icon]="['fas', 'copy']"></fa-icon>New Variant
          </button>

          <mat-form-field style="width: 15%;" appearance="outline">
            <mat-label>Offer Variant Name*</mat-label>
            <mat-select [formControl]="newVersionName" (selectionChange)="getSelectedVersionDetail($event)">
              <mat-option *ngFor="let variant of variantsList" [value]="variant">
                {{variant.OfferVariantName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="activeNavItem !== 'versions' && variantsCount > 1">
            <mat-checkbox [disabled]="disableNewVersion" [formControl]="applyChanges">
              Apply changes <br /> to multiple variants?
            </mat-checkbox>
          </div>

          <mat-form-field *ngIf="activeNavItem !== 'versions' && variantsCount > 1 && applyChanges.value"
            style="width: 58%;" appearance="outline">
            <mat-label>Apply Changes To*</mat-label>
            <mat-chip-grid #chipList aria-label="Versions selection" [formControl]="VersionCtrl">
              <ng-container *ngIf="offerserv.selection.selected.length !== variantsList.length">
                <mat-chip-row *ngFor="let selectedVersion of offerserv.selection.selected"
                  [disabled]="newVersionName.value.Id === selectedVersion.Id" (removed)="removeTag(selectedVersion)">
                  {{selectedVersion.OfferVariantName}}
                  <button type="button" matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </ng-container>

              <mat-chip-row *ngIf="offerserv.selection.selected.length === variantsList.length">
                All Variants
              </mat-chip-row>
              <input placeholder="Select Variants..." [matChipInputFor]="chipList" [matAutocomplete]="auto"
                [formControl]="VersionCtrl">
            </mat-chip-grid>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="tagSelected($event)">
              <mat-option>
                <mat-checkbox (click)="masterToggle()" [checked]="offerserv.selection.hasValue() && isAllSelected()"
                  [indeterminate]="offerserv.selection.hasValue() && !isAllSelected()">
                  All Variants
                </mat-checkbox>
              </mat-option>
              <mat-option *ngFor="let variant of variantsList" [value]="variant">
                <mat-checkbox [disabled]="newVersionName.value.Id === variant.Id" (click)="$event.stopPropagation()"
                  (change)="$event ? offerserv.selection.toggle(variant) : null"
                  [checked]="offerserv.selection.isSelected(variant)">
                  {{variant.OfferVariantName}}
                </mat-checkbox>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </ng-container>

      <div [ngSwitch]="labelType">
        <nav *ngSwitchCase="LabelType.OFFER" mat-tab-nav-bar [tabPanel]="tabPanel">
          <a mat-tab-link [routerLink]="['details']" routerLinkActive #details="routerLinkActive"
            queryParamsHandling="preserve" [active]="details.isActive" (click)="setCurrentActiveItem('details')">Details
          </a>
          <a *ngIf="readOnlyMode != true" mat-tab-link [disabled]="offerId == '0' || offerserv.getNewVersion !== ''"
            [routerLink]="['products']" routerLinkActive queryParamsHandling="preserve" #products="routerLinkActive"
            [active]="products.isActive" (click)="setCurrentActiveItem('products')">Products
          </a>
          <a *ngIf="readOnlyMode != true" mat-tab-link [disabled]="offerId == '0' || offerserv.getNewVersion !== ''"
            [routerLink]="['versions']" routerLinkActive queryParamsHandling="preserve" #versions="routerLinkActive"
            [active]="versions.isActive" (click)="setCurrentActiveItem('versions')">Versions
          </a>
          <a *ngIf="readOnlyMode != true" mat-tab-link [disabled]="offerId == '0' || offerserv.getNewVersion !== ''"
            [routerLink]="['assets']" routerLinkActive queryParamsHandling="preserve" #assets="routerLinkActive"
            [active]="assets.isActive" (click)="setCurrentActiveItem('assets')">Assets
          </a>
          <a *ngIf="readOnlyMode != true" mat-tab-link [disabled]="offerId == '0' || offerserv.getNewVersion !== ''"
            [routerLink]="['activitylog']" routerLinkActive queryParamsHandling="preserve"
            #activitylog="routerLinkActive" [active]="activitylog.isActive"
            (click)="setCurrentActiveItem('activitylog')">Activity Log
          </a>
        </nav>
        <nav *ngSwitchCase="LabelType.PROMO" mat-tab-nav-bar [tabPanel]="tabPanel">
          <a mat-tab-link [routerLink]="['promodetails']" routerLinkActive #promodetails="routerLinkActive"
            queryParamsHandling="preserve" [active]="promodetails.isActive"
            (click)="setCurrentActiveItem('promodetails')">Promo Details
          </a>
          <a *ngIf="readOnlyMode != true" mat-tab-link [disabled]="offerId == '0' || offerserv.getNewVersion !== ''"
            [routerLink]="['versions']" routerLinkActive queryParamsHandling="preserve" #versions="routerLinkActive"
            [active]="versions.isActive" (click)="setCurrentActiveItem('versions')">Versions
          </a>
          <a *ngIf="readOnlyMode != true" mat-tab-link [disabled]="offerId == '0' || offerserv.getNewVersion !== ''"
            [routerLink]="['assets']" routerLinkActive queryParamsHandling="preserve" #assets="routerLinkActive"
            [active]="assets.isActive" (click)="setCurrentActiveItem('assets')">Assets
          </a>
          <a *ngIf="readOnlyMode != true" mat-tab-link [disabled]="offerId == '0' || offerserv.getNewVersion !== ''"
            [routerLink]="['activitylog']" routerLinkActive queryParamsHandling="preserve"
            #activitylog="routerLinkActive" [active]="activitylog.isActive"
            (click)="setCurrentActiveItem('activitylog')">Activity Log
          </a>
        </nav>
        <nav *ngSwitchCase="LabelType.OFFERGROUP" mat-tab-nav-bar [tabPanel]="tabPanel">
          <a mat-tab-link [routerLink]="['offergrpdetails']" routerLinkActive #offergrpdetails="routerLinkActive"
            queryParamsHandling="preserve" [active]="offergrpdetails.isActive"
            (click)="setCurrentActiveItem('offergrpdetails')">Details
          </a>
          <a *ngIf="readOnlyMode != true" mat-tab-link [disabled]="offerId == '0' || offerserv.getNewVersion !== ''"
            [routerLink]="['offers']" routerLinkActive queryParamsHandling="preserve" #offers="routerLinkActive"
            [active]="offers.isActive" (click)="setCurrentActiveItem('offers')">Offers
          </a>
          <a *ngIf="readOnlyMode != true" mat-tab-link [disabled]="offerId == '0' || offerserv.getNewVersion !== ''"
            [routerLink]="['versions']" routerLinkActive queryParamsHandling="preserve" #versions="routerLinkActive"
            [active]="versions.isActive" (click)="setCurrentActiveItem('versions')">Versions
          </a>
          <a *ngIf="readOnlyMode != true" mat-tab-link [disabled]="offerId == '0' || offerserv.getNewVersion !== ''"
            [routerLink]="['assets']" routerLinkActive queryParamsHandling="preserve" #assets="routerLinkActive"
            [active]="assets.isActive" (click)="setCurrentActiveItem('assets')">Assets
          </a>
        </nav>
      </div>
      <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </div>
  </ng-container>
  <ng-container *ngIf="viewModel.montageBuilderStatus==true">
    <app-montage-editor></app-montage-editor>
  </ng-container>
</ng-container>