import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { catchError, combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { DEFAULT_SNACKBAR_CONFIG, Logger, PreviewMode, UtilityService } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { OfferPromo, PromoDomain, OfferPromoService, OffergroupOfferRankDialogComponent } from '../..';
import { SelectionModel } from '@angular/cdk/collections';
import { Status } from '../../models/offer-promo-status';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { EventWorkflowService } from '../../services/event-workflow.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommentsComponent } from 'pr1-ui-components';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';


const log = new Logger('OfferGroupOffersTableComponent');

@Component({
  selector: 'app-offergroup-offers-table',
  templateUrl: './offergroup-offers-table.component.html',
  styleUrls: ['./offergroup-offers-table.component.scss'],
})
export class OfferGroupOffersTableComponent<TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>> implements OnInit, AfterViewInit {
  offerGroupId: any;
  form = new FormGroup({
    versions: new FormArray([]),
  });
  viewModel$ = combineLatest([
    this.offerpromoService.getofferGrpOffers$,
    this.offerpromoService.isLoading$,
    this.offerpromoService.offerGrptotalRecords$,
    this.offerpromoService.offerGrpPage$,
  ]).pipe(
    map(([offers, isLoading, totalRecords, page]) => {
      if (offers.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0;
        page.pageIndex = 0;
        this.offerpromoService.offerGrpPage(page);
        this.offerpromoService.offerGrpReload();
      }
      this.form = new FormGroup({
        versions: new FormArray([]),
      });
      offers.map((x) => this.addVersion(x));
      return { offers, isLoading, totalRecords, page };
    })
  );
  flexMediaWatcher!: Subscription;
  displayedColumns = [
    'select', 'Image', 'Name', 'Headline', 'BodyCopy', 'EventType', 'Status', 'Tags', 'VersionCodes', 'Version', 'Rank', 'Comments', 'Versions', 'Actions',];
  offerpromosList: TPromoDomain[] = [];
  selection = new SelectionModel<TPromoDomain>(true, []);
  versionsList: any[] = [];
  readOnlyMode = false;
  PreviewMode = PreviewMode;
  @Input() set readOnly(val: boolean) {
    this.readOnlyMode = val;
    this.cdRef.detectChanges();
    this.offerpromoService.setOfferDetailsReadOnly(val);
  }
  isCommentDialogOpened: boolean = false;
  constructor(
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    public utilityService: UtilityService,
    private mediaObserver: MediaObserver,
    private ewService: EventWorkflowService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    log.debug('init');
    this.offerGroupId = this.route.snapshot.params['offerId']
    this.offerpromoService.commentDialogBoxStatus$.subscribe((status: boolean) => { this.isCommentDialogOpened = status });
    this.offerpromoService.getofferGrpOffers$.subscribe((data) => {
      this.offerpromosList = data;
    });

    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        )
      )
      .subscribe((change) => {
        if (change.some((x) => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'Name', 'Actions'];
        } else if (change.some((x) => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'Name', 'Headline', 'Actions'];
        } else {
          this.displayedColumns = ['select', 'Image', 'Name', 'Headline', 'BodyCopy', 'EventType', 'Status', 'Tags', 'VersionCodes', 'Version', 'Rank', 'Comments', 'Versions', 'Actions',];
        }
      });
  }

  ngAfterViewInit() {
    if (this.ewService.readOnlyMode != null) {
      this.readOnlyMode = this.ewService.readOnlyMode;
      this.cdRef.detectChanges();
    }
  }

  onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = './assets/images/default-image.png'; // Path to your default image
  }

  updateStatus(status: Status) {
    let selectedRecordsDetails: any;
    selectedRecordsDetails = JSON.parse(
      JSON.stringify(this.getSelectedSectionRecords())
    );
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateTableStatus(status: Status, row: any) {
    let selectedRecordsDetails: any;
    selectedRecordsDetails = [JSON.parse(JSON.stringify(row))];
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateRecordsStatus(selectedRecordsDetails: Array<any>, status: any) {
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      let statustoUpdate: any = [];
      for (let index = 0; index <= selectedRecordsDetails.length - 1; index++) {
        let variants = this.filterVariants(selectedRecordsDetails[index]);
        let recordstoupdate = variants.map((x: any) => {
          return {
            Id: x.Id,
            Status: status,
            Label: selectedRecordsDetails[index].EventOfferType
          };
        });
        statustoUpdate = [...statustoUpdate, ...recordstoupdate];
      }
      this.offerpromoService.updateStatus(statustoUpdate).subscribe({
        next: () => {
          this.offerpromoService.offerGrpReload();
          this.clearSelection();
          this.offerpromoService.updateRecordStatus(new Date());
        },
      });
    }
  }

  filterVariants(record: any): any[] {
    // Check if record.Detail.Id is equal to any Variant.BaseId
    if (record.Detail.Id === record.Variants[0]?.BaseId) {
      return record.Variants; // Return all Variants
    }

    // Otherwise, filter Variants with BaseId equal to record.Detail.Id
    return record.Variants.filter((variant: any) => variant.Id === record.Detail.Id);
  }

  onSort(sortState: Sort): void {
    this.offerpromoService.offerGrpSort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.offerpromoService.offerGrpPage(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.offerpromosList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.offerpromosList.forEach((row) => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  canEdit(): boolean {
    return !this.readOnlyMode;
  }

  openRankDialog(offerType: PromoDomain<OfferPromo>): void {
    const dialogRef = this.dialog.open(OffergroupOfferRankDialogComponent, {
      width: '350px',
      height: '100%',
      data: { offer: offerType }
    });

    dialogRef.afterClosed().subscribe(() => {
      console.info('Offergroup Offer Rank Dialog Component closed!');
    })

    dialogRef.componentInstance.onSelection.subscribe((res: any) => {
      let offerType: PromoDomain<OfferPromo> = res.offer || null;
      if (this.offerGroupId && offerType && res.rank) {
        this.offerpromoService.updateAssociationRankByContext(this.offerGroupId, offerType.Detail.Id as string, res.rank).subscribe({
          next: () => {
            this.matSnackBar.open(`${offerType.Detail.Name} rank updated`, 'OK', DEFAULT_SNACKBAR_CONFIG);
            this.offerpromoService.offerGrpReload();
          },
          error: (error) => {
            console.error('Error updating asset rank', error);

            if (error.error.value) {
              throw new Error(error.error.value);
            } else {
              throw new Error(error.message);
            }
          }
        });
      } else {
        console.log('Asset Rank update details', res);
      }
    });
  }

  onVariantChange(variantId: string, offerType: PromoDomain<OfferPromo>): void {
    this.fetchVariantDetails(variantId, offerType);
  }

  fetchVariantDetails(variantId: string, offerType: PromoDomain<OfferPromo>): void {
    if (variantId && offerType.EventOfferType && offerType.EventOfferType !== 'OFFERGROUP') {
      this.offerpromoService.getVariantDetails(variantId, offerType.EventOfferType)
        .pipe(
          catchError(error => {
            log.error('Error fetching variant details', error);
            return [];
          })
        )
        .subscribe(response => {
          // Update offerType properties with the response, excluding Variants and VariantsCount
          Object.keys(response).forEach(key => {
            if (key !== 'Variants' && key !== 'VariantsCount') {
              (offerType as any)[key] = response[key];
            }
          });
        });
    }
  }

  get versions(): FormArray {
    return this.form.get('versions') as FormArray;
  }
  addVersion(offer: any) {
    if (this.versions) {
      this.versions.push(new FormControl(offer.Variants[0]));
    }
  }
  openDialog($event: any, offer: any) {
    if (this.isCommentDialogOpened == false) {
      let commentElementStyle = document.getElementById(offer.DomainId) as HTMLElement;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        headline: offer.Variants[0].Headline,
        channel: offer.EventOfferType,
        parentId: offer.Variants[0].Id,
        clientKey: offer.Variants[0].ClientKey,
        bodyCopy: offer.Variants[0].BodyCopy,
      };
      let confirmDialog = this.dialog.open(CommentsComponent, dialogConfig);
      confirmDialog.afterOpened().subscribe(() => {
        if (commentElementStyle) {
          commentElementStyle.style.color = "blue";
          this.offerpromoService.commentDialogBoxStatusBehaviorSubject.next(true);
        }
      });
      confirmDialog.afterClosed().subscribe((data) => {
        if (data > 0) {
          Object(offer).CommentsCount = data;
        }
        if (commentElementStyle) {
          commentElementStyle.style.color = "black";
          this.offerpromoService.commentDialogBoxStatusBehaviorSubject.next(false);
        }

      });
    }
  }

  ngOnDestroy(): void {
    this.offerpromoService.resetOfferGrpPage();
  }
}
