import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { v4 as uuidv4 } from 'uuid';
import { AdWeek, Event } from '..';
import { OrganizationService } from 'src/app/@shared/services/organization.service';
import { EventDomain } from '../models/event-domain.model';
import { Filter } from 'src/app/@shared/models/filter.model';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';

// constants
///TODO: move this to a constants pattern
export const DEFAULT_DATE_RANGE = {
  startDate: '',
  endDate: '',
};

@Injectable({
  providedIn: 'root',
})

export class EventService<TEvent extends Event> {
  public selectedEventName: any = null;

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // initialize behavior subjects
  private dateRangeBehaviorSubject = new BehaviorSubject(DEFAULT_DATE_RANGE);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private dialogPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private uploadEventPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private uploadEventSortBehaviorSubject = new BehaviorSubject({ active: 'StartDate', direction: 'desc', });
  private sortBehaviorSubject = new BehaviorSubject({ active: 'StartDate', direction: 'desc', });
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private dialogSearchBehaviorSubject = new BehaviorSubject<string>('');
  private uploadEventSearchBehaviorSubject = new BehaviorSubject<string>('');
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private viewModeBehaviorSubject = new BehaviorSubject<string>('TABLE');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private selectedEventForAdBehaviorSubject = new BehaviorSubject<EventDomain<TEvent>[]>([]);

  // we do not wish to expose our behavior subjects.  create public observables
  public dateRange$ = this.dateRangeBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public uploadEventSort$ = this.uploadEventSortBehaviorSubject.asObservable();
  public dialogPage$ = this.dialogPageBehaviorSubject.asObservable();
  public uploadEventPage$ = this.uploadEventPageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public dialogSearch$ = this.dialogSearchBehaviorSubject.asObservable();
  public uploadEventSearch$ = this.uploadEventSearchBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();
  public selectedEventForAd$ = this.selectedEventForAdBehaviorSubject.asObservable();

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.dateRangeBehaviorSubject.pipe(debounceTime(50)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, dateRange, sort, search, filters, reload]) => {

      let _orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'DivisionNames' || sort.active == 'EventTypeName') {
        _orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          $expand: 'Detail',
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: _orderby,
          $count: true,
        }
      });
      if (dateRange.startDate) {
        params = params.append('windowBegin', `${dayjs(dateRange.startDate).format('MM/DD/YYYY')}`);
      }
      if (dateRange.endDate) {
        params = params.append('windowEnd', `${dayjs(dateRange.endDate).format('MM/DD/YYYY')}`);
      }

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
        params = this.buildFilterParams(filters, params);
      }

      return params;
    })
  );

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public dialogParams$ = combineLatest([
    this.dialogPageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.dialogSearchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload]) => {

      let _orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'DivisionNames' || sort.active == 'EventTypeName') {
        _orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          $expand: 'Detail',
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: _orderby,
          $count: true,
        }
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  // create the events observable that calls http get when any of our parameters change
  private dialogEventsResponse$ = this.dialogParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventDomains/GetEventWithNoAd`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // event listing
  public dialogEvents$: Observable<EventDomain<TEvent>[]> = this.dialogEventsResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of event records based on filtering
  public dialogTotalRecords$: Observable<number> = this.dialogEventsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // set the current page
  dialogPage(page: any) {
    this.dialogPageBehaviorSubject.next(page);
  }

  // sets the search phrase
  dialogSearch(search: string) {
    this.dialogSearchBehaviorSubject.next(search);
    this.dialogPageBehaviorSubject.next(DEFAULT_PAGING);
  }

  clearEventForAd() {
    this.selectedEventForAdBehaviorSubject.next([]);
  }

  setEventForAd(event: EventDomain<TEvent>[]) {
    this.selectedEventForAdBehaviorSubject.next(event);
  }

  public uploadparams$ = combineLatest([
    this.uploadEventPageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.uploadEventSortBehaviorSubject,
    this.uploadEventSearchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload]) => {
      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          $expand: 'Detail',
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: `Detail/${sort.active} ${sort.direction}`,
          $count: true,
        }
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  // create the events observable that calls http get when any of our parameters change
  private eventsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventDomains`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // event listing
  public events$: Observable<EventDomain<TEvent>[]> = this.eventsResponse$.pipe(
    map((res: any) => res.value)
  );

  ///TODO: remove this observable
  private alleventsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventDomains/GetAll`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  private uploadEventsResponse$ = this.uploadparams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventDomains`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );


  // event listing
  public uploadevents$: Observable<EventDomain<TEvent>[]> = this.uploadEventsResponse$.pipe(
    map((res: any) => res.value)
  );

  // event listing
  public allevents$: Observable<EventDomain<TEvent>[]> = this.eventsResponse$.pipe(
    map((res: any) => res.value)
  );

  // event listing
  public eventsList$: Observable<EventDomain<TEvent>[]> = this.alleventsResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of event records based on filtering
  public totalRecords$: Observable<number> = this.eventsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );


  // total number of event records
  public totalEventsRecords$: Observable<number> = this.alleventsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // total number of event records
  public uploadEventsRecords$: Observable<number> = this.uploadEventsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // event listing by ad week
  public adWeeks$: Observable<AdWeek<EventDomain<TEvent>>[]> = this.eventsResponse$.pipe(
    map((res: any) => this.buildStartDates(res.value))
  );

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  uploadEventpage(page: any) {
    this.uploadEventPageBehaviorSubject.next(page);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

   // sets the sort property and order
   uploadEventsort(sort: any) {
    this.uploadEventSortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // sets the search phrase
  uploadEventsearch(search: string) {
    const page = this.uploadEventPageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.uploadEventSearchBehaviorSubject.next(search);
    this.uploadEventPageBehaviorSubject.next(page);
  }

  // reloads/refreshes the event listing
  reload() {
    // reload the event data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // changes the view mode of the event listing
  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
    if (mode.toUpperCase() !== "EXPANSION") {
      this.pageBehaviorSubject.next(DEFAULT_PAGING);
    }
  }

  // sets the date range of the event listing
  dateRange(start?: string, end?: string) {
    const viewMode = this.viewModeBehaviorSubject.value;
    const range = { startDate: start as string, endDate: end as string };
    this.dateRangeBehaviorSubject.next(range);
    if (viewMode == "EXPANSION") {
      let paging = JSON.parse(JSON.stringify(DEFAULT_PAGING));
      paging.pageSize = 1000;
      this.pageBehaviorSubject.next(paging);
    } else {
      this.pageBehaviorSubject.next(DEFAULT_PAGING);
    }
  }

  // gets an event by id
  getEvent(eventId: string): Observable<TEvent> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Events/${eventId}`;
    return this.httpClient.get<TEvent>(url).pipe(tap((eventDetails: any) => this.selectedEventName = eventDetails.EventName))
  }

  // an event domain by id
  getEventDomain(eventId: string): Observable<EventDomain<TEvent>> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventDomains/${eventId}?$expand=Detail`;
    return this.httpClient.get<EventDomain<TEvent>>(url);
  }

  // up-serts event
  saveEvent(event: TEvent) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventDomains/`;
    if (!event.Id || event.Id === '0') {
      // create new record
      return this.httpClient.post(url, event);
    } else {
      // edit existing record
      url += `${event.Id}`;
      return this.httpClient.put(url, event);
    }
  }

  // up-serts event domain
  saveEventDomain(eventDomain: EventDomain<TEvent>) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventDomains/`;

    if (!eventDomain.Detail.Id || eventDomain.Detail.Id === '0') {
      // clear the Detail Id
      eventDomain.Detail.Id = '';
      // create new record
      return this.httpClient.post(url, eventDomain);
    } else {
      // edit existing record
      url += `${eventDomain.Detail.Id}`;
      return this.httpClient.put(url, eventDomain);
    }
  }

  // deletes an event by id
  deleteEvent(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Events/${id}`;
    return this.httpClient.delete(url);
  }

  deleteEvents(events: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventDomains/DeleteEvents?${this.prepareAssetStringtoDelete(events)}`;
    return this.httpClient.post(url, events);
  }

  prepareAssetStringtoDelete(events: any) {
    let urlParams = '';
    if (events && events.length > 0) {
      for (let index = 0; index <= events.length - 1; index++) {
        urlParams += `eventIds=${events[index]?.Detail?.Id}`;
        if (index != events.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }

  // adds filters to the event listing
  addFilters(newFilters: Filter[]) {
    const filters = this.filterBehaviorSubject.value;

    newFilters.forEach(filter => {
      if (filters.findIndex(item => item.fieldName.toLowerCase() === filter.fieldName.toLowerCase() && item.value.toLowerCase() === filter.value.toLowerCase()) === -1) {
        filters.push(filter)
      }
    });

    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the event listing
  removeFilter(filter: Filter) {
    const filters = this.filterBehaviorSubject.value.filter(item => item !== filter);
    this.filterBehaviorSubject.next(filters)
  }

  // removes a filter from the event listing
  removeFilterByFieldName(fieldName: string) {
    const filters = this.filterBehaviorSubject.value.filter(item => item.fieldName.toLowerCase() !== fieldName.toLowerCase());
    this.filterBehaviorSubject.next(filters)
  }

  // removes all filters for the event listing
  clearFilters() {
    this.dateRangeBehaviorSubject.next(DEFAULT_DATE_RANGE);
    this.filterBehaviorSubject.next([]);
  }

  // builds a list of events by ad week
  private buildAdWeeks(eventDomains: EventDomain<TEvent>[]): AdWeek<EventDomain<TEvent>>[] {

    dayjs.extend(isSameOrAfter);

    const dateRange = this.dateRangeBehaviorSubject.getValue();
    let adWeekArray: AdWeek<EventDomain<TEvent>>[] = [];

    const startDate = dayjs(dateRange.startDate).startOf("week");
    const endDate = dayjs(dateRange.endDate).startOf("week");
    let currentDate = endDate;

    while (currentDate.isSameOrAfter(startDate)) {
      const filteredEvents = eventDomains.filter(eventDomain => {
        return dayjs(eventDomain.Detail.StartDate).isSameOrAfter(currentDate) && dayjs(eventDomain.Detail.StartDate).isBefore(currentDate.add(7, 'day'));
      });

      if (filteredEvents.length > 0) {
        const adWeek: AdWeek<EventDomain<TEvent>> = { title: currentDate.format('MM/DD/YYYY'), eventDomains: filteredEvents };
        adWeekArray.push(adWeek);
      }

      currentDate = currentDate.subtract(7, 'day');
    }

    adWeekArray = adWeekArray.sort((a: any, b: any) => { return Date.parse(a.title) - Date.parse(b.title) });
    return adWeekArray;
  }

  // builds a list of events by start date
  private buildStartDates(eventDomains: EventDomain<TEvent>[]): AdWeek<EventDomain<TEvent>>[] {
    let startDatesArray: AdWeek<EventDomain<TEvent>>[] = [];
    let startDateList = eventDomains
      .map(eDomain => dayjs(eDomain.Detail.StartDate).format('MM/DD/YYYY'))
      .filter((startDate, index, self) => self.indexOf(startDate) === index);

    startDateList.forEach(startDate => {
      let date = dayjs(startDate);
      let filteredEvents = eventDomains.filter(eDomain => date.isSame(dayjs(eDomain.Detail.StartDate), 'day'));

      if (filteredEvents.length > 0) {
        const startDates: AdWeek<EventDomain<TEvent>> = { title: date.format('MM/DD/YYYY'), eventDomains: filteredEvents };
        startDatesArray.push(startDates);
      }
    });

    startDatesArray = startDatesArray.sort((a: any, b: any) => { return Date.parse(a.title) - Date.parse(b.title) });

    return startDatesArray;
  }

  // build the list of filter parameters
  private buildFilterParams(filters: Filter[], params: HttpParams): HttpParams {
    // get the division id filters
    const divisionIdFilters = filters.filter(item => item.fieldName.toLowerCase() === 'divisionid');

    // loop through the division id filters and add filter statement to param
    divisionIdFilters.forEach((filter) => {
      params = params.append('divisionIds', filter.value);
    });

    // get the event type id filter
    const eventTypeIdFilter = filters.filter(item => item.fieldName.toLowerCase() === 'eventtypeid');

    // loop through the event type id filter and add filter statement to param
    if (eventTypeIdFilter.length > 0) {
      const eventType: any = filters.filter(item => item.fieldName.toLowerCase() === 'eventtypeid')[0].value;
      params = params.append('eventTypeId', eventType.Id);
    }

    // return the params
    return params;
  }

  downloadEventsJSON() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Events/EtlExport`;
    window.open(url, '_blank');
  }

  downloadEventsCSV() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Events/Export`;
    window.open(url, '_blank');
  }

  cloneEvent(request: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventDomains/CloneEvent`;
    return this.httpClient.post<any>(url, request);
  }

  exportAllGalleyProof(eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventDomains/ExportGalleyProof?eventId=${eventId}&$orderby=Detail/Rank asc&mode=table`;
    return this.httpClient.post(url, {}, { responseType: 'blob' })
  }

}
