import { AssetDetail, Offer } from "..";

export interface IOfferVariantViewModel {
    Id?: string;
    OfferVariantName?: string;
    BaseId?: string;
    IsBaseVariant: boolean;
}

export interface OfferGroupDomain<TOffer extends Offer> {
    Detail: TOffer;
    ParentId?: string;
    DomainId?: string;

    // Association related fields for edit/create
    EventId?: string;
    BadgeAssetId?: string;
    OfferTagIds: Array<string | null>;
    VersionIds: Array<string | null>;
    OfferTypeId?: string;
    ProductCategoryIds: string[];

    // Fields for list or details
    EventOfferType?: string;
    FacingAsset?: AssetDetail;
    OfferTagNames?: string;
    VersionNames?: string;
    PricingPreviewHtml?: string;
    Variants: IOfferVariantViewModel[];
    VariantsCount: number;
    CommentsCount: number;
    OfferGroupAssociatedRank?: number;
    ParentOfferGroupId?: string | null;

    // Print channel related
    PrintPageId?: string;
    PageNumber?: string;
    Position?: number;

    // Montage related
    ImageEditorConfigId?: string;

    // JAI
    OfferHasProducts?: boolean;
    JAICreativeId?: string;

    OfferIds: Array<string | null>;
    OffersCount: number;

    [key: string]: any; // For dynamic properties
}