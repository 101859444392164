import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import { Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Offer, OfferDomain, OfferService, Version, VersionService } from '../..';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

const log = new Logger('OfferGroupVersionsComponent');

interface DialogData {
  event: string;
  versions: Version[];
}

@Component({
  selector: 'app-offer-group-versions',
  templateUrl: './offer-group-versions.component.html',
  styleUrls: ['./offer-group-versions.component.scss']
})

export class OfferGroupVersionsComponent implements OnInit {
  search = new FormControl<string>('');
  viewModel$ = combineLatest([
    this.versionService.offerGroupVersions$,
    this.versionService.isLoading$,
    this.versionService.offerGroupVersionstotalRecords$,
    this.versionService.offerGroupVersionsPage$,
  ]).pipe(
    map(([versions, isLoading, totalRecords, page]) => {
      if (versions.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0;
        page.pageIndex = 0;
        this.versionService.page(page);
        this.versionService.reload();
      }
      this.versionList = new MatTableDataSource(versions);
      return { versions, isLoading, totalRecords, page }
    }),
  );
  versionList: MatTableDataSource<Version> = new MatTableDataSource();
  selection = new SelectionModel<Version>(true, [], true, (version1, version2) => version1.Id === version2.Id);
  displayedColumns = ['select', 'VersionName'];
  constructor(
    private versionService: VersionService<Version>,
    public offerService: OfferService<Offer, OfferDomain<Offer>>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<OfferGroupVersionsComponent>) { }

  ngOnInit(): void {
    log.debug('init');
    this.versionService.resetOfferGroupVersionsPage();
    this.versionService.eventId = this.dialogData?.event;
    if (this.dialogData?.versions?.length > 0) {
      this.selection = new SelectionModel<Version>(true, this.dialogData.versions, true, (version1, version2) => version1.Id === version2.Id);
    }
  }

  ngOnDestroy() {
    this.versionService.resetPage();
  }

  onSearch(event: any) {
    this.versionService.offerGroupVersionsSearch(event.target.value);
  }

  clearSearch() {
    this.search.setValue('');
    this.versionService.offerGroupVersionsSearch('');
  }

  onSort(sortState: Sort): void {
    this.versionService.offerGroupVersionsSort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.versionService.offerGroupVersionsPage(pageEvent);
  }

  closeDialog() {
    this.dialogRef.close();
    this.versionService.offerGroupVersionsSearch('');
  }

  saveData() {
    this.dialogRef.close(this.selection.selected);
    this.versionService.offerGroupVersionsSearch('');
  }

  isAllSelected(): boolean {
    return this.versionList.data.every(version => this.selection.isSelected(version));
  }

  toggleSelectAll(checked: boolean): void {
    if (checked) {
      this.versionList.data.forEach(version => {
        if (!this.selection.isSelected(version)) {
          this.selection.select(version);
        }
      });
    } else {
      this.versionList.data.forEach(version => {
        this.selection.deselect(version);
      });
    }
  }

  isSomeSelectedOnPage(): boolean {
    return this.versionList.data.some(version => this.selection.isSelected(version));
  }

  toggleSelect(checked: boolean, version: Version) {
    if (checked) {
      if (!this.selection.isSelected(version)) {
        this.selection.select(version);
      }
    } else {
      this.selection.deselect(version);
    }
  }

  getSelectedSectionRecords() {
    return this.selection.selected;
  }
}
