<form [formGroup]="form">
    <h2 *ngIf="offer?.Detail?.Name" mat-dialog-title>Offer Name : {{offer.Detail.Name}}</h2>
    <mat-dialog-content>
        <mat-form-field appearance="outline">
            <mat-label>Rank</mat-label>
            <input matInput type="number" formControlName="rank" min="1" required />

            <!-- Validation messages -->
            <div *ngIf="rankControl?.invalid && rankControl?.touched">
                <mat-error *ngIf="rankControl?.hasError('required')">Rank is required.</mat-error>
                <mat-error *ngIf="rankControl?.hasError('min')">Rank must be at least 1.</mat-error>
            </div>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-stroked-button type="button" (click)="closeDialog()">Cancel</button>
        <button mat-raised-button type="button" color="accent" [disabled]="form.invalid" (click)="save()">Save</button>
    </mat-dialog-actions>
</form>