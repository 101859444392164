<ng-container *ngIf="viewModel$ | async as viewModel">
  <ng-container *ngIf="viewModel.eventOfferTypes.length > 0">
    <table mat-table [dataSource]="viewModel.eventOfferTypes" matSort (matSortChange)="onSort($event)"
      matSortDisableClear [matSortStart]="'desc'">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
          <mat-checkbox *ngIf="canEdit()" (change)="toggleSelectAll($event.checked)" [checked]="isAllSelected()"
            [indeterminate]="isSomeSelectedOnPage() && !isAllSelected()"></mat-checkbox>
        </th>
        <td class="no-ellipsis" mat-cell *matCellDef="let record">
          <mat-checkbox *ngIf="canEdit()" (click)="$event.stopPropagation()"
            (change)="toggleSelect($event.checked, record)" [checked]="selection.isSelected(record)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!--  Image Column -->
      <ng-container matColumnDef="Image">
        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Preview</th>
        <td mat-cell *matCellDef="let record">
          <img mat-card-md-image
            [src]="record?.FacingAsset ? utilityService.getOfferPreviewUrl(record?.FacingAsset, PreviewMode.Thumbnail) : './assets/images/default-image.png'"
            alt="Offer image" (error)="onImageError($event)">
        </td>
      </ng-container>

      <!-- OfferName Column -->
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"
          [resizeColumn]="true">
          Name</th>
        <td mat-cell *matCellDef="let record"> {{ record?.Detail?.Name }}</td>
      </ng-container>

      <!-- Headline Column -->
      <ng-container matColumnDef="Headline">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Headline"
          [resizeColumn]="true">Headline</th>
        <td mat-cell *matCellDef="let record">{{ record?.Detail?.Headline }}</td>
      </ng-container>

      <!-- BodyCopy Column -->
      <ng-container matColumnDef="BodyCopy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by BodyCopy"
          [resizeColumn]="true">Body Copy</th>
        <td mat-cell *matCellDef="let record">{{ record?.Detail?.BodyCopy }}</td>
      </ng-container>

      <!-- EventType Column -->
      <ng-container matColumnDef="EventType">
        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Type</th>
        <td mat-cell *matCellDef="let record">{{ record?.EventOfferType == 'OFFERGROUP'? 'OFFER GROUP':record?.EventOfferType }}
          <span *ngIf="record?.EventOfferType == 'OFFERGROUP'">({{record?.OffersCount}})</span>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="Status">
        <th class="w-120" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status"
          [resizeColumn]="true">Status</th>
        <td [matMenuTriggerFor]="canEdit() ? menu: null" mat-cell *matCellDef="let record">
          <div class="offer-status column-status">
            <span [matMenuTriggerFor]="canEdit() ? menu : null" class="ellipsis-3-line {{ getStatusClass(record) }}">
              {{ getStatusText(record) }}
              <mat-icon *ngIf="canEdit()" class="icon">arrow_drop_down</mat-icon>
            </span>
          </div>

          <mat-menu #menu="matMenu" class="status-menu">
            <button type="button" mat-menu-item class="statusAPPROVED"
              [disabled]="record?.Detail?.Status === 'APPROVED'"
              (click)="updateStatus(Status.APPROVED, record)">APPROVED
            </button>
            <button type="button" mat-menu-item class="statusPENDING" [disabled]="record?.Detail?.Status === 'PENDING'"
              (click)="updateStatus(Status.PENDING, record)">PENDING
            </button>
            <button type="button" mat-menu-item class="statusSUPPRESSED"
              [disabled]="record?.Detail?.Status === 'SUPPRESSED'"
              (click)="updateStatus(Status.SUPPRESSED, record)">SUPPRESSED
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <!-- Version Column -->
      <ng-container matColumnDef="Version">
        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Variant</th>
        <td mat-cell *matCellDef="let record; let i = index;">
          <mat-form-field class="versions" appearance="outline" *ngIf="record?.Variants?.length > 1">
            <mat-select [(value)]="record.Detail.Id" placeholder="Select Variant"
              (selectionChange)="onVariantChange($event.value, record)">
              <mat-option *ngFor="let variant of record?.Variants" [value]="variant?.Id">
                {{variant?.OfferVariantName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <!-- Rank Column -->
      <ng-container matColumnDef="Rank">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Rank"
          [resizeColumn]="true">
          Rank</th>
        <td mat-cell *matCellDef="let record">{{ record?.Detail?.Rank }}</td>
      </ng-container>

      <!-- Comments Column -->
      <ng-container matColumnDef="Comments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Comments by Status"
          [resizeColumn]="true">Comments</th>
        <td mat-cell *matCellDef="let record;let i = index">
          <ng-container *ngIf="record?.CommentsCount !== undefined && record?.CommentsCount! > 0; else noComments">
            <div id="{{record?.DomainId}}" (click)="openDialog($event, record)" class="comments-dialog font-18">
              <i class="fa fa-comment font-12" aria-hidden="true"></i>
              <span class="comments-count">{{record?.CommentsCount}}</span>
            </div>
          </ng-container>
          <ng-template #noComments>
            <span id="{{record?.DomainId}}" (click)="openDialog($event, record)" class="comments-dialog font-18">
              <i class="fa fa-comment-o font-12" aria-hidden="true"></i>
            </span>
          </ng-template>
        </td>
      </ng-container>

      <!-- Tags Column -->
      <ng-container matColumnDef="Tags">
        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Tags</th>
        <td title="{{ record?.OfferTagNames }}" mat-cell *matCellDef="let record">{{record?.OfferTagNames }}</td>
      </ng-container>

      <!-- VersionCodes Column -->
      <ng-container matColumnDef="VersionCodes">
        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Versions</th>
        <td title="{{ record?.VersionNames }}" mat-cell *matCellDef="let record">{{record?.VersionNames }}</td>
      </ng-container>

      <!-- Versions Column -->
      <ng-container matColumnDef="Versions">
        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" >Variants</th>
        <td mat-cell *matCellDef="let record" >
          <button *ngIf="record?.VariantsCount && record?.VariantsCount > 1 && record?.EventOfferType === 'OFFER'"
            mat-button type="button" color="accent" [routerLink]="[record?.Detail?.Id, 'childversions']">
            Variants({{record?.VariantsCount}})</button>
          <button *ngIf="record?.VariantsCount && record?.VariantsCount > 1 && record?.EventOfferType === 'PROMO'"
            mat-button type="button" color="accent" [routerLink]="[record?.Detail?.Id, 'promo', 'childversions']">
            Variants({{record?.VariantsCount}})</button>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef class="pl-20">Actions</th>
        <td mat-cell *matCellDef="let record">
          <button *ngIf="record?.EventOfferType === 'OFFER'" class="offer-button" type="button" mat-button
            color="accent" [routerLink]="[record?.Detail?.Id, 'details']" [queryParams]="{ offer_filters:'true' }">
            <span>{{canEdit()?'Edit':'View Details'}}</span>
          </button>
          <button *ngIf="record?.EventOfferType === 'PROMO'" class="offer-button" type="button" mat-button
            color="accent" [routerLink]="[record?.Detail?.Id, 'promos', 'promodetails']"
            [queryParams]="{ offer_filters:'true' }">
            <span>{{canEdit()?'Edit':'View Details'}}</span>
          </button>
          <button *ngIf="record?.EventOfferType === 'OFFERGROUP'" class="offer-button" type="button" mat-button
            color="accent" [routerLink]="[record?.Detail?.Id, 'offergroups', 'offergrpdetails']"
            [queryParams]="{ offer_filters:'true' }">
            <span>{{canEdit()?'Edit':'View Details'}}</span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <!-- pager -->
    <mat-paginator [length]="viewModel?.totalRecords" [pageSize]="viewModel?.page?.pageSize"
      [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)"
      showFirstLastButtons [pageIndex]="viewModel?.page?.pageIndex">
    </mat-paginator>
  </ng-container>

  <ng-container *ngIf="viewModel?.eventOfferTypes?.length === 0">
    <table>
      <tr>
        <td class="no-offers">
          <h2>No offers Found</h2>
        </td>
      </tr>
    </table>
  </ng-container>
</ng-container>