<div fxLayout="row" fxLayoutAlign="space-between start">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
    <h2>Add Offers</h2>
    <mat-form-field appearance="outline" class="mat-form-field-no-padding">
      <mat-label>{{searchPlaceholder}}</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input matInput [(ngModel)]="searchQuery" type="text" placeholder="Search" #searchInput (input)="onSearch()" />
      <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput?.value"
        (click)="clearSearch(searchInput)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div fxFlex="auto" fxLayoutAlign="end center">
    <mat-button-toggle-group #group="matButtonToggleGroup" value="{{defaultViewMode}}"
      (change)="toggleView($event.value)" aria-label="Select view mode">
      <mat-button-toggle value="{{ViewMode.Card}}" matTooltip="Card view" aria-label="Card view">
        <mat-icon>grid_view</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="{{ViewMode.Table}}" matTooltip="Table view" aria-label="Table view">
        <mat-icon>table_view</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<div class="search-content" fxLayout="row" fxLayout.lt-md="column" fxFlexFill fxLayoutGap="16px">
  <div fxFlex *ngIf="viewModel$ | async as viewModel">
    <div fxLayout="column">
      <div class="grid-container" *ngIf="viewModel.offers.length > 0; else noSearchResults">
        <ng-container *ngIf="defaultViewMode == ViewMode.Card; else defaultView">
          <mat-checkbox [checked]="isAllSelected()" [indeterminate]="isSomeSelectedOnPage() && !isAllSelected()"
            (change)="toggleSelectAll($event.checked)" [disabled]="viewModel.offers.length === 0">Select
            All</mat-checkbox>
          <div class="grid">
            <mat-card appearance="outlined" *ngFor="let OfferDomain of viewModel.offers; index as i">
              <mat-card-content>
                <div class="grid-col-span-2 offer-name" title="{{OfferDomain.Detail.Name }}" fxLayout="row wrap"
                  fxLayoutAlign="start start" fxLayoutGap="15px">
                  <mat-checkbox (click)="$event.stopPropagation()" fxFlex="30px" class="card-checkbox"
                    (change)="toggleSelect($event.checked, OfferDomain)" [checked]="selection.isSelected(OfferDomain)"
                    [disabled]="isOfferSelected(OfferDomain)">
                  </mat-checkbox>
                  <div>{{OfferDomain.Detail.Status}}</div>
                  <div>Rank: {{OfferDomain.Detail.Rank}}</div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
                  <img mat-card-md-image *ngIf="OfferDomain.FacingAsset" [src]="utilityService.getOfferPreviewUrl(OfferDomain.FacingAsset, PreviewMode.Medium)"
                    alt="Offer image" (error)="onImageError($event)" />
                  <img mat-card-md-image *ngIf="!OfferDomain.FacingAsset" src="./assets/images/default-image.png"
                    alt="Offer image" />
                  <div class="offer-content" fxLayout="column" fxFlex>
                    <div class="offer-headline">
                      <div class="ellipsis-3-line offer-headline-bold">{{ OfferDomain.Detail.Headline }}</div>
                    </div>
                    <div class="offer-bodycopy">
                      <div class="ellipsis-3-line">{{ OfferDomain.Detail.BodyCopy }}</div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>

        <ng-template #defaultView>
          <table *ngIf="viewModel.offers.length" mat-table [dataSource]="offersListData" matSort
            (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'">

            <ng-container matColumnDef="select">
              <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
                <mat-checkbox [checked]="isAllSelected()" [indeterminate]="isSomeSelectedOnPage() && !isAllSelected()"
                  (change)="toggleSelectAll($event.checked)" [disabled]="viewModel.offers.length === 0">
                </mat-checkbox>
              </th>
              <td class="no-ellipsis" mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleSelect($event.checked, row)"
                  [checked]="selection.isSelected(row)" [disabled]="isOfferSelected(row)"></mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="Image">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Preview</th>
              <td mat-cell *matCellDef="let record">
                <img class="offer-image" *ngIf="record.FacingAsset" [src]="utilityService.getOfferPreviewUrl(record.FacingAsset, PreviewMode.Thumbnail)" alt="Offer image"
                  (error)="onImageError($event)">
                <img mat-card-md-image *ngIf="!record.FacingAsset" src="./assets/images/default-image.png"
                  alt="Offer image">
              </td>
            </ng-container>

            <ng-container matColumnDef="Id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id"
                [resizeColumn]="true">
                Id
              </th>
              <td mat-cell *matCellDef="let record">{{ record.Detail.Id }}</td>
            </ng-container>

            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"
                [resizeColumn]="true">
                Offer Name
              </th>
              <td mat-cell *matCellDef="let record"> {{ record.Detail.Name }}</td>
            </ng-container>

            <ng-container matColumnDef="ClientKey">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey"
                [resizeColumn]="true">
                Client Key
              </th>
              <td mat-cell *matCellDef="let record">{{ record.Detail.ClientKey }}</td>
            </ng-container>

            <ng-container matColumnDef="Headline">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Headline"
                [resizeColumn]="true">
                Headline
              </th>
              <td mat-cell *matCellDef="let record">{{ record.Detail.Headline }}</td>
            </ng-container>

            <ng-container matColumnDef="BodyCopy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by BodyCopy"
                [resizeColumn]="true">
                BodyCopy
              </th>
              <td mat-cell *matCellDef="let record">{{ record.Detail.BodyCopy }}</td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="Status">
              <th class="w-120" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status"
                [resizeColumn]="true">
                Status
              </th>
              <td mat-cell *matCellDef="let record">{{ record.Detail.Status }}</td>
            </ng-container>

            <ng-container matColumnDef="Rank">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Rank"
                [resizeColumn]="true">
                Rank
              </th>
              <td mat-cell *matCellDef="let record">{{ record.Detail.Rank }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </ng-template>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center" *ngIf="viewModel.offers.length > 0">
        <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
          [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" [hidden]="!viewModel.offers.length"
          showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
        </mat-paginator>
      </div>

      <!-- Buttons: Cancel and Select -->
      <div fxLayout="row" fxLayoutAlign="end center" *ngIf="viewModel.offers.length > 0">
        <button mat-stroked-button (click)="closeDialog()">Cancel</button>
        <button mat-flat-button color="primary" (click)="onSelect()"
          [disabled]="viewModel.offers.length == 0 || !isSelectionChanged">Add to Group</button>
      </div>

      <ng-template #noSearchResults class="no-records">
        <div class="no-records">
          <h2 aria-live="polite">No records found.</h2>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button mat-stroked-button (click)="closeDialog()">Cancel</button>
        </div>
      </ng-template>
    </div>

  </div>
</div>