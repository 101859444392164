import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, } from '@angular/material/dialog';
import { UploadAssetsEventsComponent } from '../../components/upload-assets-events/upload-assets-events.component';
import { VersionService } from '../../services/version.service';
import { Event, EventService, OfferPromo, OfferPromoService, PromoDomain, Version, } from '../..';
import { DEFAULT_SNACKBAR_CONFIG, Logger, OrganizationService } from 'src/app/@shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CloneType } from '../../models/clone-type.model';
import { Router } from '@angular/router';

interface DialogData {
  eventId: string;
}

const log = new Logger('CloneOffersComponent');

@Component({
  selector: 'app-clone-offers',
  templateUrl: './clone-offers.component.html',
  styleUrls: ['./clone-offers.component.scss'],
})
export class CloneOffersComponent<
  TVersion extends Version,
  TOfferPromo extends OfferPromo,
  TPromoDomain extends PromoDomain<OfferPromo>,
  TEvent extends Event
> implements OnInit {
  cloneForm: FormGroup = new FormGroup({
    sourceEvent: new FormControl(null, [Validators.required]),
    destinationEvent: new FormControl(null, [Validators.required]),
    versions: new FormControl([]),
    IncludeVersions: new FormControl(true),
    KeepCurrentStatus: new FormControl(true),
  });
  versionList: TVersion[] = [];
  @ViewChild('searchInput') searchInput: any;
  sourceEventId: string = '';
  destinationEventId: string = '';
  viewMode = 'TABLE';
  @ViewChild('offerPromoCloneTable', { static: false })
  offerPromoCloneTable: any;
  @ViewChild('offerPromoCloneCards', { static: false })
  offerPromoCloneCards: any;
  entityType$ = this.offerpromoService.cloneEntityType$;
  CloneType = CloneType;

  constructor(
    private dialog: MatDialog,
    private versionService: VersionService<TVersion>,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private eventService: EventService<TEvent>,
    public dialogRef: MatDialogRef<
      CloneOffersComponent<TVersion, TOfferPromo, TPromoDomain, TEvent>
    >,
    private matSnackBar: MatSnackBar,
    private organizationService: OrganizationService,
    private router: Router
  ) {
    this.sourceEventId = this.data.eventId;
    this.destinationEventId = this.data.eventId;
    this.offerpromoService.cloneEventId = this.data.eventId;
    this.eventService.getEvent(this.sourceEventId).subscribe((res) => {
      this.cloneForm.controls['sourceEvent'].setValue(res.EventName);
      this.cloneForm.controls['destinationEvent'].setValue(res.EventName);
      this.getVersionsList(this.sourceEventId);
    });
    this.cloneForm.controls['versions'].valueChanges.subscribe(res => {
      if (res?.length >= this.versionList.length && !res?.includes('allVersions')) {
        this.cloneForm.controls['versions'].setValue([...this.cloneForm.controls['versions'].value, 'allVersions']);
      } else if (res?.length <= this.versionList.length && res?.includes('allVersions')) {
        const values = [...this.cloneForm.controls['versions'].value.filter((val: any) => val !== 'allVersions')];
        this.cloneForm.controls['versions'].setValue(values);
      }
    })
  }
  ngOnInit(): void {
    log.debug('init');
  }

  get selectedRecords() {
    if (this.offerPromoCloneCards) {
      return this.offerPromoCloneCards.getSelectedSectionRecords();
    }
    if (this.offerPromoCloneTable) {
      return this.offerPromoCloneTable.getSelectedSectionRecords();
    }
    return [];
  }

  openSourceEventsDialog() {
    const sourceEventsDialog = this.dialog.open(UploadAssetsEventsComponent, {
      width: '60%',
      height: '90%',
    });
    sourceEventsDialog.afterClosed().subscribe((res) => {
      if (res) {
        this.cloneForm.controls['sourceEvent'].patchValue(
          res.Detail?.EventName
        );
        this.sourceEventId = res.Detail?.Id;
        this.offerpromoService.cloneEventId = res.Detail?.Id;
      }
    });
  }

  openDestinationEventsDialog() {
    const destinationEventsDialog = this.dialog.open(UploadAssetsEventsComponent, {
      width: '60%',
      height: '90%',
    });
    destinationEventsDialog.afterClosed().subscribe((res) => {
      if (res) {
        this.cloneForm.controls['destinationEvent'].patchValue(
          res.Detail?.EventName
        );
        this.destinationEventId = res.Detail?.Id;
        this.getVersionsList(res.Detail?.Id);
      }
    });
  }

  getVersionsList(eventId: string) {
    this.versionService.getVersionsForVariants(eventId).subscribe((res) => {
      this.versionList = res;
    });
  }

  onSearch(event: any) {
    this.offerpromoService.cloneSearch(event.target.value);
  }

  clearSearch() {
    this.offerpromoService.cloneSearch('');
    this.searchInput && (this.searchInput.nativeElement.value = '');
  }

  cloneEvent() {
    const versionIds = [...this.cloneForm.controls['versions'].value.filter((version: string) => version !== 'allVersions')];
    const request = {
      SourceEventId: this.sourceEventId,
      DestinationEventId: this.destinationEventId,
      OfferPromoIds: this.selectedRecords.map((record: any) => record.Detail.Id),
      VersionIds: versionIds,
      IncludeVersions: this.cloneForm.controls['IncludeVersions'].value,
      KeepCurrentStatus: this.cloneForm.controls['KeepCurrentStatus'].value
    }
    if (this.sourceEventId != this.destinationEventId) {
      request.IncludeVersions = false;
    }
    this.offerpromoService.cloneOfferPromos(request).subscribe({
      next: (res: any) => {
        this.matSnackBar.open(
          `Offer/Promo(s) cloned`,
          'OK',
          DEFAULT_SNACKBAR_CONFIG
        );
        this.dialogRef.close();
        this.offerpromoService.reload();
        this.offerpromoService.cloneEntity(CloneType.EVENT);
        const url = (`${this.organizationService.organization?.apiPath}`).toLowerCase();
        let currentUrl = `${url}/events/${res.Id}/offers`;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      },
      error: err => {
        this.matSnackBar.open(
          `Failed to clone Offer/Promo(s)`,
          'OK',
          DEFAULT_SNACKBAR_CONFIG
        );
      }
    })
  }

  closeDialog() {
    this.dialogRef.close();
    this.offerpromoService.cloneEntity(CloneType.EVENT);
  }

  toggleSelectAllVersions(event: any) {
    if (this.cloneForm.controls['versions'].value.length >= this.versionList.length) {
      this.cloneForm.controls['versions'].setValue(null);
    } else {
      this.cloneForm.controls['versions'].setValue([...this.versionList.map(version => version.Id), 'allVersions']);
    }
  }
}
